.app {

}

.container {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content {
    display: inline;
}

.logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.logo {
    height: 50%;
    width: auto;

    max-height: 20rem;
}

.text {
    margin-top: 1rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headline {
    font-size: 1.5rem;
    font-weight: bold;
}

.headline.english {
    font-weight: normal;
}

.message-container {
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.message {
    width: 80%;
    max-width: 30rem;
}

.message.english {
    margin-top: 0.5rem;
    font-weight: normal;
}

.link-container {
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.link {
    margin-top: 1rem;

    width: 80%;
    max-width: 30rem;

    font-size: 0.8rem;
}

.loader {
    margin-top: 2rem;
    border: 0.2rem solid lightgray;
    border-top: 0.2rem solid #b71c1c;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
